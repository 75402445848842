'use strict';

angular.module('kljDigitalLibraryApp')
    .service('SubscriptionService', ["$q", "common", "appConfig", "$localStorage",
        function($q, common, appConfig, $localStorage) {

            var baseUrl = '/api/user_subscription/';

            this.personalList = function() {
                var deferred = $q.defer();

                common.callApi('GET',
                    baseUrl + 'mySubscriptions',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {

                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;
            }

            this.getHistory = function() {
                var deferred = $q.defer();

                common.callApi('GET',
                    baseUrl + 'history',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {

                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;
            }

            this.getSubscriptionDetails = function(id) {
                var deferred = $q.defer();

                common.callApi('GET',
                    '/api/subscription/get/' + id,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {
                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;
            }

            this.getMySubscription = function(id) {
                var deferred = $q.defer();

                common.callApi('GET',
                    '/getDetails/' + id,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {
                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;
            }

            this.suggestionList = function() {
                var deferred = $q.defer();

                common.callApi('POST',
                    '/api/subscription/getSubscriptions',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {
                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;
            }

            this.promoCodeVerification = function(plan) {
                    var deferred = $q.defer();
                    var body = { coupon: { subscription_id: plan._id } };

                    common.callApi('POST',
                        '/api/coupon/applyCoupon/' + plan.couponCode,
                        appConfig.EMPTY_STRING,
                        appConfig.CONTENT_TYPE,
                        body
                    )

                    .then(function(response) {
                        deferred.resolve(response.data);
                    })

                    .catch(function(error) {
                        deferred.reject(error);
                    })
                    return deferred.promise;
                }
                //Get counter for subscription renewal
            this.getDaysForRenewal = function(plan) {
                var deferred = $q.defer();
                // if( $localStorage.counter ) {
                //     deferred.resolve($localStorage.counter);
                // } else {
                common.callApi('GET',
                    '/api/user_subscription/getRenewalCounter',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {
                    // if (!$localStorage.counter) {
                    //     $localStorage.counter = response.data;
                    // }
                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                        deferred.reject(error);
                    })
                    // }
                return deferred.promise;
            }

            // subscribed or not

            this.checkStatus = function(payload) {
                var deferred = $q.defer();

                common.callApi('POST',
                    baseUrl + 'isExist',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    payload
                )

                .then(function(response) {
                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;
            }

            //Get all available plans
            this.availablePlans = function() {
                var deferred = $q.defer();

                common.callApi('GET',
                    '/api/subscription/availableSubscriptions',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {
                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;
            }

            this.getdetailsUsingOrder = function(id) {
                var deferred = $q.defer();

                common.callApi('GET',
                    baseUrl + 'getSubscriptionusingOrder/' + id,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {
                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;
            }

            this.getExpiryDate = function() {
                var deferred = $q.defer();
                baseUrl = '/api/user/';
                common.callApi('GET',
                    baseUrl + 'getExpiryDate',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE)

                .then(function(response) {
                    deferred.resolve(response.data);
                })

                .catch(function(error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            }

            this.get_order_intent = function(payload) {
                
                var subscriptionBaseUrl = '/api/order';
                let deffered = $q.defer();
                common.callApi('POST',
                    subscriptionBaseUrl + '/createOrderIntent',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE, 
                    payload
                )
                .then(function successCallback(response) {
                    // this callback will be called asynchronously
                    // when the response is available
                    deffered.resolve(response.data);
                }, function errorCallback(error) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                    deffered.reject(error);
                });
                return deffered.promise;
            }

            this.confirmOrder = function(subscription_id, txnid, accesscode) {
                var subscriptionBaseUrl = '/api/payment';
                let deffered = $q.defer();
                common.callApi('POST',
                    '/api/payments/confirm/accesscodeSubscription',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE, 
                    {
                        subscription: subscription_id,
                        accesscode: accesscode,
                        txnid:txnid,
                    }
                )
                .then(function successCallback(response) {
                    // this callback will be called asynchronously
                    // when the response is available
                    deffered.resolve(response.data);
                }, function errorCallback(error) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                    deffered.reject(error);
                });
                return deffered.promise;
            }

        }
    ]);